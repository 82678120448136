<template>
  <div class="wrap">
    <div class="filter">
      <div class="search_div">
        <el-input
          style="width: 300px; height: 200px"
          :rows="7"
          type="textarea"
          class="area"
          auto-complete=""
          v-model="search_str"
          placeholder="请输入公司手机号，每行一个,不填默认导出全部"
        >
        </el-input>
      </div>
      <div class="search_div">
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </div>
    </div>


    <div  style="clear:both;"></div>
    <div class="main_content" v-for="(item, index) in tableData" :key="index">
    <div class="show">
      <p style=" font-size: 24px; font-weight: bold; ">{{ item.company_name }}</p>
      
      <div class="main_content" v-for="(img, index2) in item.img_data" :key="index2">
        <img :src="img.img_data" width="300" height="200" style=" float: left;"/>
      </div>
      <div  style="clear:both;"></div>
      <div>

        <div class="main_content" v-for="(jd, index1) in item.jd_list" :key="index1">
        
          <p class="smk-xsx">岗位名称：{{ jd.jd_title }}</p>
  
          <p>薪资待遇：{{ jd.jd_salary_min }}-{{ jd.jd_salary_max }}元</p>
          <p>岗位优势：{{ jd.jd_tags_name.join(",") }} </p>


          <p class="smk-xsx"> 职位描述：{{ jd.jd_desc }}</p>
          <p>地址：{{ item.company_map_detail }}</p>
          <br> 
        </div>
      
      </div>

      <br>   <br> 
    </div>
    <div class="operation" v-if="item.jd_tags_name != ''">
      <div
        class="boon"
        v-for="(item1, index1) in item.jd_tags_name"
        :key="index1"
      >
        {{ item1 }}
      </div>
    </div>
  </div>







  </div>

  
</template>

<script>
import { daochu_jd } from "@/api/hr24_api/api_all.js";

export default {
  data() {
    return {
      search_str: "",
      index: 0,
      tableData: [],
      search_arr: [],
    };
  },
  mounted() {},
  methods: {
    search(e) {
      if (this.search_str) {
        this.search_arr = this.search_str.split("\n");
        console.log("----------");
        console.log(this.search_arr);
      }
      var content = "";
      daochu_jd({ search_arr: this.search_arr }).then((res) => {
        if (!res.code) {
          console.log("ssssssssssss");
          console.log(res.data);
          this.tableData = res.data;

          for (let i = 0; i < res.data.length; i++) {
            const detail = res.data[i];
            console.log(detail.company_name);

            content += detail.company_name + "\n";

            for (let j = 0; j < detail.jd_list.length; j++) {
              const jd = detail.jd_list[j];
              console.log(jd.jd_title);

              content += "\t 岗位名称：" + jd.jd_title + "\n";
              content +=
                "\t 薪资待遇：" +
                jd.jd_salary_min +
                "-" +
                jd.jd_salary_max +
                "\n";
              content += "\t 职位描述：" + jd.jd_desc + "\n";
              content += "\t 岗位优势：" + jd.jd_tags_name.join(",") + "\n\n";
            }
            content += "\n\n================================================\n";
          }

          console.log(content);

          // const element = document.createElement('a')
          // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))  //res.data.data是接口返回的数据
          // element.setAttribute('download', '职位导出')
          // element.style.display = 'none'
          // element.click()
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}
.search_div {
  float: left;
  width: 350px;
}
body{background-color: #ffffff}
</style>
